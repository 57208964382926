import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight, isNumber } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import withAuth from '../../hoc/with-auth';
import { getCurrentUserRemainingPosts } from '../../../common/store/current-user/current-user-selectors';
import { POST_LIMIT_REACHED } from '../../components/messages/message-types';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { MODAL_TYPE_POSTING_DISABLED } from '../../components/modals/posting-disabled-modal/posting-disabled-modal-type';
import withPermissions from '../../hoc/with-permissions';
import { getIsPostingDisabled } from '../../selectors/forum-data-selectors';

class CreatePostHandler extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    categorySlug: PropTypes.string,
    navigateWithinForum: PropTypes.func.isRequired,
    forPublicUser: PropTypes.func.isRequired,
    showMessage: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    remainingPosts: PropTypes.number,
    postType: PropTypes.string,
    isPostingDisabled: PropTypes.bool,
    can: PropTypes.func,
  };

  static defaultProps = {
    postType: DISCUSSION,
  };

  handleClick = () => {
    const {
      navigateWithinForum,
      categorySlug,
      showMessage,
      remainingPosts,
      postType,
      openModal,
      can,
      isPostingDisabled,
    } = this.props;

    if (isPostingDisabled) {
      return openModal(MODAL_TYPE_POSTING_DISABLED, { canEnable: can('edit', 'forum') });
    }
    if (isNumber(remainingPosts) && remainingPosts < 1) {
      showMessage(POST_LIMIT_REACHED);
      return;
    }
    const postTypeSlug = postType === DISCUSSION ? '/create-post' : '/create-question';
    const path = categorySlug ? `/${categorySlug}${postTypeSlug}` : postTypeSlug;
    navigateWithinForum(path);
  };

  render() {
    const { children, forPublicUser } = this.props;
    const child = React.Children.only(children);
    return React.cloneElement(child, { onClick: forPublicUser(this.handleClick) });
  }
}

const mapRuntimeToToProps = (state, ownProps, actions) => ({
  navigateWithinForum: actions.navigateWithinForum,
  showMessage: actions.showMessage,
  remainingPosts: getCurrentUserRemainingPosts(state),
  isPostingDisabled: getIsPostingDisabled(state),
  openModal: actions.openModal,
});

export default flowRight(
  connect(mapRuntimeToToProps),
  withAuth,
  withPermissions,
)(CreatePostHandler);
