import React from 'react';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import ActionButton from '../../components/action-button';
import ensureAuth from '../../hoc/ensure-auth';
import ActionsDivider from '../../components/actions-divider';

export const doesUserHavePermissionsToSeeCategoryActions = (can, category) => {
  // the logic is that everyone can see subscribe button expect those who cannot access category. Edit check is just in case for owners :)
  return (
    can('subscribe', 'category', category) ||
    (can('list', 'category', category) && !isPrivate(category)) ||
    can('edit', 'category', category)
  );
};

const ActionButtonAuth = ensureAuth(ActionButton);

export const mapCategoryActionsToItems = (actions, t, theme) => {
  return actions.map(action => {
    if (action.component) {
      return action.component;
    }

    if (action.isDivider) {
      return <ActionsDivider />;
    }

    const ActionComponent = action.isAuthRequired ? ActionButtonAuth : ActionButton;

    return (
      <ActionComponent
        dataHook={action.dataHook}
        isLink={!!action.to}
        to={action.to}
        queryParams={action.queryParams}
        onClick={action.onClick}
        theme={theme}
      >
        {action.icon}
        {t(action.label)}
      </ActionComponent>
    );
  });
};
