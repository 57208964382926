import HeaderNavigationDesktop from './header-navigation-desktop.component';
import HeaderNavigationMobile from './header-navigation-mobile.component';
import forDevice from '../../hoc/for-device';
import { flowRight } from 'lodash';
import { RootState } from '../../reducers/root-state';
import { MainPageType } from './models';
import { getActiveFeedName } from './store/header-navigation.selectors';
import { connect } from '../../../common/components/runtime-context';
import { getIsMainPageEnabled } from '../../selectors/app-settings-selectors';
import { getCategories } from '../../../common/selectors/categories-selectors';

const HeaderNavigation = forDevice(HeaderNavigationMobile, HeaderNavigationDesktop);

const mapRuntimeToProps = (state: RootState, _: any, __: any, host: any) => {
  const isMainPageCategoriesList = getIsMainPageEnabled(state, host.style);
  const mainPageType = isMainPageCategoriesList ? MainPageType.Categories : MainPageType.Posts;

  return {
    mainPageType,
    isCategoriesPageVisible: getCategories(state).length > 1,
    activeFeedType: getActiveFeedName(state, mainPageType),
  };
};

export default flowRight(connect(mapRuntimeToProps))(HeaderNavigation);
