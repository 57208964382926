import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_COMPACT,
  LAYOUT_TYPE_PINBOARD,
} from '@wix/communities-forum-client-commons/dist/src/constants/layout-types';

export enum LayoutTypes {
  PINBOARD = LAYOUT_TYPE_PINBOARD,
  CLASSIC = LAYOUT_TYPE_CLASSIC,
  COMPACT = LAYOUT_TYPE_COMPACT,
}

export enum LayoutTypeNames {
  CLASSIC = 'classic',
  COMPACT = 'compact',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}
