import React from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import ensureAuth from '../../hoc/ensure-auth';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import classNames from 'classnames';
import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { CREATE_NEW_QUESTION } from '../../constants/interactions';
import { QuestionIcon } from '../icons/question-icon';
import CreatePostHandler from '../../containers/create-post-handler';
import Button from '../button';
import ProtectedButton from '../../containers/protected-button';
import styles from './create-question-button.scss';
import { getIsPostingDisabled } from '../../selectors/forum-data-selectors';

const ButtonSecure = ensureAuth(Button);

const QuestionPostButton = ({
  categorySlug,
  t,
  isSmall = true,
  isExtended,
  contentFontClassName,
  className,
  userEventsClickCreatePost,
  isPostingDisabled,
}) => {
  const savedAction = {
    action: CREATE_NEW_QUESTION,
    args: [categorySlug ? `/${categorySlug}/create-question` : '/create-question'],
  };

  const reportBI = () => {
    userEventsClickCreatePost(QUESTION);
  };

  const renderExtended = () => (
    <ProtectedButton
      actionDetails={savedAction}
      role="menuitem"
      className={styles.button}
      data-hook="create-question"
      reportBI={reportBI}
    >
      <div
        className={classNames(
          'forum-text-color',
          'button-hover-color',
          'button-hover-fill',
          'button-hover-stroke',
          'forum-icon-fill',
          'forum-icon-stroke',
          contentFontClassName,
          styles.container,
          className,
        )}
      >
        <div className={styles.icon}>
          <QuestionIcon />
        </div>
        <div className={styles.text}>
          <span className={styles.title}>{t('create-post-button.create-new-question-title')}</span>
          <span className={styles.info}>{t('create-post-button.create-new-question-info')}</span>
        </div>
      </div>
    </ProtectedButton>
  );
  return (
    <CreatePostHandler categorySlug={categorySlug} postType={QUESTION}>
      {isExtended ? (
        renderExtended()
      ) : (
        <ButtonSecure
          data-hook="create-question"
          actionDetails={!isPostingDisabled && savedAction}
          isSmall={isSmall}
          reportBI={reportBI}
        >
          {t('create-post-button.create-new-question')}
        </ButtonSecure>
      )}
    </CreatePostHandler>
  );
};

QuestionPostButton.propTypes = {
  categorySlug: PropTypes.string,
  t: PropTypes.func.isRequired,
  isExtended: PropTypes.bool,
  isSmall: PropTypes.bool,
  isPostingDisabled: PropTypes.bool,
  contentFontClassName: PropTypes.string,
  className: PropTypes.string,
};
const mapRuntimeToProps = (state, ownProps, actions) => ({
  userEventsClickCreatePost: actions.userEventsClickCreatePost,
  isPostingDisabled: getIsPostingDisabled(state),
});

export default flowRight(
  withTranslate,
  withFontClassName,
  connect(mapRuntimeToProps),
)(QuestionPostButton);
