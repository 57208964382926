import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import DropdownButton from '../dropdown-button';
import DiscussionPostButton from '../create-discussion-button';
import QuestionPostButton from '../create-question-button';
import { HorizontalSeparator, VerticalSeparator } from '../separator';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withDeviceType from '../../hoc/with-device-type';
import styles from './create-post-button.scss';
import { connect } from '../../../common/components/runtime-context';
import withTranslate from '../../../common/components/with-translate/with-translate';

const CreatePostButton = ({
  categorySlug,
  postTypes,
  createPostCtaLabel,
  isDropdown,
  borderWidth,
  isMobile,
  userEventsClickCreatePostButton,
  t,
}) => {
  const renderNewPostDropdown = () =>
    isDropdown ? (
      <DropdownButton
        label={createPostCtaLabel ? createPostCtaLabel : t('create-post-button.create-new-post')}
        className={styles.dropdownButtons}
        dataHook="create-post-cta"
        onDropdownShow={userEventsClickCreatePostButton}
      >
        <React.Fragment>
          <DiscussionPostButton categorySlug={categorySlug} isExtended />
          <HorizontalSeparator />
        </React.Fragment>
        <QuestionPostButton categorySlug={categorySlug} isExtended />
      </DropdownButton>
    ) : (
      <div
        className={classNames(
          styles.buttons,
          'forum-card-border-color',
          'forum-card-background-color',
        )}
        style={{ borderWidth }}
      >
        <div className={styles.createButton}>
          <DiscussionPostButton categorySlug={categorySlug} isExtended />
        </div>
        {isMobile ? (
          <HorizontalSeparator className={styles.horizontalSeparator} />
        ) : (
          <VerticalSeparator />
        )}
        <div className={styles.createButton}>
          <QuestionPostButton categorySlug={categorySlug} isExtended />
        </div>
      </div>
    );

  if (postTypes.length > 1) {
    return renderNewPostDropdown();
  }
  return (
    <div>
      <DiscussionPostButton
        categorySlug={categorySlug}
        label={createPostCtaLabel ? createPostCtaLabel : t('create-post-button.create-new-post')}
      />
    </div>
  );
};

CreatePostButton.propTypes = {
  categorySlug: PropTypes.string,
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  isDropdown: PropTypes.bool,
  borderWidth: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
  userEventsClickCreatePostButton: PropTypes.func.isRequired,
};

CreatePostButton.defaultProps = {
  postTypes: [DISCUSSION],
  isDropdown: true,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  userEventsClickCreatePostButton: actions.userEventsClickCreatePostButton,
});

export default flowRight(
  withTranslate,
  withCardBorderWidth,
  withDeviceType,
  connect(mapRuntimeToProps),
)(CreatePostButton);
