import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { LAYOUT_TYPE_PINBOARD } from '@wix/communities-forum-client-commons/dist/src/constants/layout-types';
import { EXPERIMENT_USE_COMMENTING_TYPE_CLIENT } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import PostListMasonryResponsive from './post-list-masonry-responsive';
import PostListClassic from './post-list-classic';
import PostListMobile from '../post-list-mobile';
import PostListMobileClassic from '../post-list-mobile-classic';
import withDeviceType from '../../hoc/with-device-type';
import withPermissions from '../../hoc/with-permissions';
import { getLayoutType } from '../../selectors/layout-selectors';
import {
  getAllCategories,
  getUniquePostTypesInAllCategories,
} from '../../../common/selectors/categories-selectors';
import { getIsCategorySelectEnabled } from '../../selectors/app-settings-selectors';
import { getForumCreatePostCta } from '../../selectors/forum-data-selectors';
import { mapCommentingToPostType } from '../../services/map-commenting-to-post-type';
import withExperiment from '../../hoc/with-experiment';
import styles from './post-list.scss';

class PostList extends Component {
  constructor(props) {
    super(props);
    const { layout, isMobile } = this.props;
    this.state = { isInfiniteList: !isMobile && layout === LAYOUT_TYPE_PINBOARD };
  }

  getListComponent = () => {
    const { isMobile, layout } = this.props;

    let Component;
    if (isMobile) {
      if (layout === LAYOUT_TYPE_PINBOARD) {
        Component = PostListMobile;
      } else {
        Component = PostListMobileClassic;
      }
    } else {
      if (layout === LAYOUT_TYPE_PINBOARD) {
        Component = PostListMasonryResponsive;
      } else {
        Component = PostListClassic;
      }
    }
    return Component;
  };

  getListComponentProps = () => {
    const {
      isLoading,
      showLoaderInLoadMore,
      currentPagePosts,
      allPosts,
      page,
      entityCount,
      isLoaded,
      loadMore,
      changePage: handlePageChange,
      selectedCategorySlug,
      isCategorySelectEnabled,
      isCommentingTypeEnabled,
      ...props
    } = this.props;
    let postTypes =
      props.uniquePostTypesInAllCategories.length === 1
        ? props.uniquePostTypesInAllCategories
        : props.category.postTypes;

    if (isCommentingTypeEnabled && props.category.commentingType) {
      postTypes = mapCommentingToPostType(props.category.commentingType);
    }

    const showCategoryLink = !selectedCategorySlug && isCategorySelectEnabled;
    const commonProps = {
      entityCount,
      showFollowCategoryAction: true,
      showFilters: true,
      isLoading,
      showCategoryLink,
      postTypes,
    };

    if (this.state.isInfiniteList) {
      return {
        posts: allPosts,
        showLoaderInLoadMore,
        loadMore,
        ...commonProps,
        ...props,
      };
    }

    return {
      page,
      onPageChange: handlePageChange,
      isLoaded,
      posts: currentPagePosts,
      ...commonProps,
      ...props,
    };
  };

  render() {
    const containerClassName = classNames(styles.container, 'post-list');
    const Component = this.getListComponent();
    const componentProps = this.getListComponentProps();

    return (
      <div className={containerClassName}>
        <Component {...componentProps} />
      </div>
    );
  }
}

PostList.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  changePage: PropTypes.func,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  allPosts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  isMobile: PropTypes.bool,
  layout: PropTypes.number,
  entityCount: PropTypes.number,
  page: PropTypes.number,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  selectedCategorySlug: PropTypes.string,
  isCategorySelectEnabled: PropTypes.bool,
  showLoaderInLoadMore: PropTypes.bool,
  hasActiveFilter: PropTypes.bool,
  emptyStateFragment: PropTypes.node,
};

PostList.defaultProps = {
  layout: LAYOUT_TYPE_PINBOARD,
};

const mapRuntimeToProps = (state, { category, can }, actions, host) => {
  let customCtaLabel;
  let showCreatePostAction;
  const canCreatePostInCategory = c =>
    (c.isWriteProtected && can('create-post', 'category', c)) ||
    (!c.isWriteProtected && can('read', 'category', c));

  // Post list without category._id shows all posts from all categories
  if (!category._id) {
    // Check if user can post in any of the categories
    showCreatePostAction = getAllCategories(state).some(canCreatePostInCategory);
    customCtaLabel = getForumCreatePostCta(state);
  } else {
    showCreatePostAction = category && canCreatePostInCategory(category);
    customCtaLabel = category.createPostCtaLabel;
  }

  return {
    showCreatePostAction,
    layout: getLayoutType(state, host.style),
    onLikeClick: actions.incrementPostLikeCount,
    isCategorySelectEnabled: getIsCategorySelectEnabled(state, host.style),
    uniquePostTypesInAllCategories: getUniquePostTypesInAllCategories(state),
    customCtaLabel,
  };
};

export default flowRight(
  withDeviceType,
  withPermissions,
  withExperiment({
    isCommentingTypeEnabled: EXPERIMENT_USE_COMMENTING_TYPE_CLIENT,
  }),
  connect(mapRuntimeToProps), // Should stay last
)(PostList);
