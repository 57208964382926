import { SearchSuggestionsData } from '../../types';

type ReadyDropdownStateCache = {
  [key: string]: SearchInputSuggestionsDropdownLoadedState;
};

export type SearchInputSuggestionsState =
  | {
      type: 'CLOSED';
      value: string;
      dropdownState: SearchInputSuggestionsDropdownState;
      loadedResultsCache: ReadyDropdownStateCache;
    }
  | {
      type: 'OPEN';
      value: string;
      dropdownState: SearchInputSuggestionsDropdownState;
      loadedResultsCache: ReadyDropdownStateCache;
    };

type SearchInputSuggestionsDropdownReadyState = {
  type: 'READY';
  query: string;
  data: SearchSuggestionsData;
};
type SearchInputSuggestionsDropdownEmptyState = {
  type: 'EMPTY';
  query: string;
  data: SearchSuggestionsData;
};

type SearchInputSuggestionsDropdownLoadedState =
  | SearchInputSuggestionsDropdownReadyState
  | SearchInputSuggestionsDropdownEmptyState;

export type SearchInputSuggestionsDropdownState =
  | {
      type: 'CLOSED';
    }
  | {
      type: 'LOADING';
      query: string;
    }
  | SearchInputSuggestionsDropdownEmptyState
  | SearchInputSuggestionsDropdownReadyState;

export const OPEN_SUGGESTIONS = 'OPEN_SUGGESTIONS' as const;
export const openSuggestions = () => ({
  type: OPEN_SUGGESTIONS,
});

export const SET_VALUE = 'SET_VALUE' as const;
export const setValue = (payload: { value: string }) => ({
  type: SET_VALUE,
  payload,
});

export const CLEAR_VALUE = 'CLEAR_VALUE' as const;
export const clearValue = () => ({
  type: CLEAR_VALUE,
});

export const CLOSE_SUGGESTIONS = 'CLOSE_SUGGESTIONS' as const;
export const closeSuggestions = () => ({
  type: CLOSE_SUGGESTIONS,
});

export const LOAD_SUGGESTIONS = 'LOAD_SUGGESTIONS' as const;
export const loadSuggestions = (payload: { query: string }) => ({
  type: LOAD_SUGGESTIONS,
  payload,
});

export const LOADED_SUGGESTIONS = 'LOADED_SUGGESTIONS' as const;
export const loadedSuggestions = (payload: { data: SearchSuggestionsData }) => ({
  type: LOADED_SUGGESTIONS,
  payload,
});

export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS' as const;
export const clearSuggestions = () => ({
  type: CLEAR_SUGGESTIONS,
});

export const LOAD_SUGGESTIONS_FROM_CACHE = 'LOAD_SUGGESTIONS_FROM_CACHE' as const;
export const loadSuggestionsFromCache = (payload: { query: string }) => ({
  type: LOAD_SUGGESTIONS_FROM_CACHE,
  payload,
});

type SearchInputSuggestionsActions =
  | ReturnType<typeof openSuggestions>
  | ReturnType<typeof setValue>
  | ReturnType<typeof clearValue>
  | ReturnType<typeof closeSuggestions>
  | ReturnType<typeof loadSuggestions>
  | ReturnType<typeof loadedSuggestions>
  | ReturnType<typeof clearSuggestions>
  | ReturnType<typeof loadSuggestionsFromCache>;

export const reducer = (
  state: SearchInputSuggestionsState,
  action: SearchInputSuggestionsActions,
): SearchInputSuggestionsState => {
  switch (action.type) {
    case OPEN_SUGGESTIONS:
      return {
        type: 'OPEN',
        value: state.value,
        dropdownState: state.dropdownState,
        loadedResultsCache: state.loadedResultsCache,
      };
    case CLOSE_SUGGESTIONS:
      return {
        type: 'CLOSED',
        value: state.value,
        dropdownState: state.dropdownState,
        loadedResultsCache: state.loadedResultsCache,
      };
    case SET_VALUE:
      return {
        type: 'OPEN',
        value: action.payload.value,
        dropdownState: state.type === 'OPEN' ? state.dropdownState : { type: 'CLOSED' },
        loadedResultsCache: state.loadedResultsCache,
      };
    case CLEAR_VALUE:
      return {
        type: state.type,
        value: '',
        dropdownState: { type: 'CLOSED' },
        loadedResultsCache: state.loadedResultsCache,
      };
    case LOAD_SUGGESTIONS:
      return state.type === 'CLOSED'
        ? { ...state }
        : {
            type: 'OPEN',
            value: state.value,
            dropdownState: { type: 'LOADING', query: action.payload.query },
            loadedResultsCache: state.loadedResultsCache,
          };
    case LOADED_SUGGESTIONS:
      const dropdownState: SearchInputSuggestionsDropdownLoadedState = {
        type: action.payload.data.forumSearchData.totalCount > 0 ? 'READY' : 'EMPTY',
        query: action.payload.data.query,
        data: action.payload.data,
      };
      return {
        type: state.type,
        value: state.value,
        dropdownState,
        loadedResultsCache: {
          ...state.loadedResultsCache,
          [action.payload.data.query]: dropdownState,
        },
      };
    case CLEAR_SUGGESTIONS:
      return {
        type: state.type,
        value: state.value,
        dropdownState: { type: 'CLOSED' },
        loadedResultsCache: state.loadedResultsCache,
      };
    case LOAD_SUGGESTIONS_FROM_CACHE:
      return {
        type: state.type,
        value: state.value,
        dropdownState: state.loadedResultsCache[state.value],
        loadedResultsCache: state.loadedResultsCache,
      };
    default:
      return { ...state };
  }
};
