import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import CreatePostHandler from '../../containers/create-post-handler';
import CreatePostCard from '../create-post-card';
import ensureAuth from '../../hoc/ensure-auth';
import withExperiment from '../../hoc/with-experiment';
import {
  QUESTION,
  DISCUSSION,
} from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import {
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
} from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import { CREATE_NEW_POST, CREATE_NEW_QUESTION } from '../../constants/interactions';
import { MODAL_TYPE_CREATE_POST_ACTIONS_MOBILE } from '../modals/action-sheet/action-sheet-modal-types';

const SecureCreatePostCard = ensureAuth(CreatePostCard);

const CreatePostCta = ({
  categorySlug,
  postTypes,
  openModal,
  userEventsClickCreatePostButton,
  createPostCtaLabel,
}) => {
  const multiplePostTypesEnabled = postTypes.length > 1;
  const isQuestion = !multiplePostTypesEnabled && postTypes.includes(QUESTION);
  const pageSlug = isQuestion ? ROUTE_CREATE_QUESTION : ROUTE_CREATE_POST;
  const handleOnClick = () => {
    openModal(MODAL_TYPE_CREATE_POST_ACTIONS_MOBILE, { categorySlug });
    userEventsClickCreatePostButton();
  };

  return (
    <div>
      {multiplePostTypesEnabled ? (
        <CreatePostCard
          showQuestionLabel={false}
          onClick={handleOnClick}
          createPostCtaLabel={createPostCtaLabel}
        />
      ) : (
        <CreatePostHandler
          categorySlug={categorySlug}
          postType={isQuestion ? QUESTION : DISCUSSION}
        >
          <SecureCreatePostCard
            createPostCtaLabel={createPostCtaLabel}
            actionDetails={{
              action: isQuestion ? CREATE_NEW_QUESTION : CREATE_NEW_POST,
              args: [categorySlug ? `/${categorySlug}${pageSlug}` : `${pageSlug}`],
            }}
          />
        </CreatePostHandler>
      )}
    </div>
  );
};

CreatePostCta.propTypes = {
  categorySlug: PropTypes.string,
  createPostCtaLabel: PropTypes.string,
  isMobileHeaderEnabled: PropTypes.bool,
  isCtaRenameEnabled: PropTypes.bool,
  userEventsClickCreatePostButton: PropTypes.func.isRequired,
};

CreatePostCta.defaultProps = {
  postTypes: [DISCUSSION],
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  openModal: actions.openModal,
  userEventsClickCreatePostButton: actions.userEventsClickCreatePostButton,
});

export default flowRight(connect(mapRuntimeToProps))(CreatePostCta);
