import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Breadcrumbs from '../../components/breadcrumbs';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import DesktopHeader from '../desktop-header';
import { connect } from '../../../common/components/runtime-context';
import { getLocation } from '../../../common/store/location/location-selectors';
import { renderSearchInput } from './render-search-input';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { InternalPageTabsDesktopHeader } from './index';
import { getForumCreatePostCta } from '../../selectors/forum-data-selectors';
import { getRouteParams } from '../../../common/router/router-selectors';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { getCategoryFilter } from '../../selectors/filter-selectors';

const InternalPageDesktopHeader = ({
  routes,
  params,
  isForumTabsEnabled,
  customCtaLabel,
  ...props
}) => {
  if (isForumTabsEnabled) {
    return <InternalPageTabsDesktopHeader params={params} customCtaLabel={customCtaLabel} />;
  }

  return (
    <DesktopHeader
      left={<Breadcrumbs routes={routes} params={params} customCtaLabel={customCtaLabel} />}
      right={<BreadcrumbsWidget searchInput={renderSearchInput()} params={params} />}
      {...props}
    />
  );
};

InternalPageDesktopHeader.propTypes = {
  // routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  customCtaLabel: PropTypes.string,
};

const mapRuntimeToProps = state => {
  let customCtaLabel;

  const params = getRouteParams(state);
  const getRouteCategory = () => getCategoryBySlug(state, params.categorySlug);
  const getFilterCategory = () => getCategoryBySlug(state, getCategoryFilter(state));
  const category = getFilterCategory() || getRouteCategory() || { _id: null };

  if (!category._id) {
    customCtaLabel = getForumCreatePostCta(state);
  } else {
    customCtaLabel = category?.createPostCtaLabel;
  }

  return {
    location: getLocation(state),
    customCtaLabel,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
  }),
)(InternalPageDesktopHeader);
