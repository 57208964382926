import { FeedType, MainPageType } from '../models';
import { RootState } from '../../../reducers/root-state';
import { getPreviousMatches, getRouteParams } from '../../../../common/router/router-selectors';
import { get } from 'lodash';
import { FEED_QUERY_PARAM_NAME } from '../constants';
import { getQuery, getLocation } from '../../../../common/store/location/location-selectors';
import { getCurrentUser } from '../../../../common/store/current-user/current-user-selectors';
import { getCategoryBySlug } from '../../../../common/selectors/categories-selectors';
import detectRoute, { CATEGORIES_PAGE } from '../../../services/detect-route';
import { getIsMainPageEnabled } from '../../../selectors/app-settings-selectors';

export const getHeaderNavigation = (state: RootState) => state.headerNavigation;

export const getActiveNavigationItemName = (state: RootState): FeedType | null =>
  getHeaderNavigation(state).activePage;

export const getFeedType = (state: RootState): FeedType =>
  get(getQuery(state), FEED_QUERY_PARAM_NAME);

export const getActiveFeedName = (state: RootState, mainPageType: MainPageType): FeedType => {
  const categorySlug = getRouteParams(state)?.categorySlug;
  const postSlug = getRouteParams(state)?.postSlug;

  if (categorySlug && !postSlug) {
    return FeedType.Categories;
  }

  const activePageName = getActiveNavigationItemName(state);

  if (activePageName) {
    return activePageName;
  }

  return {
    [MainPageType.Categories]: FeedType.Categories,
    [MainPageType.Posts]: FeedType.Posts,
  }[mainPageType];
};

export const getActiveSiteMemberId = (state: RootState): string | undefined => {
  const currentSiteMemberId = getCurrentUser(state)?.siteMemberId;
  const activePageName = getActiveNavigationItemName(state);

  if (activePageName === FeedType.MyPosts && currentSiteMemberId) {
    return currentSiteMemberId;
  }
};

export const getActiveSelectorItem = (state: RootState, mainPageType: MainPageType): FeedType => {
  const categorySlug = getRouteParams(state).categorySlug;
  const postSlug = getRouteParams(state).postSlug;

  const activePageName = getActiveNavigationItemName(state);

  if (categorySlug && !postSlug) {
    return categorySlug;
  }

  if (activePageName) {
    return activePageName;
  }

  return {
    [MainPageType.Categories]: FeedType.Categories,
    [MainPageType.Posts]: FeedType.Posts,
  }[mainPageType];
};

export const getTabsReferrer = (state: RootState, pervMatchIndex = 1) => {
  const route = getPreviousOrigin(state, pervMatchIndex);
  const prevMatches = getPreviousMatches(state);
  const prevQueryFeedType = prevMatches[pervMatchIndex]?.queryParams[FEED_QUERY_PARAM_NAME];

  return routeToTabsName(state, route, prevQueryFeedType);
};

export const getTabsOrigin = (state: RootState) => {
  const route = getCurrentOrigin(state);
  const queryFeedType = getFeedType(state);

  return routeToTabsName(state, route, queryFeedType);
};

const getCurrentOrigin = (state: RootState) => {
  return detectRoute(getLocation(state));
};

const getPreviousOrigin = (state: RootState, pervMatchIndex = 1): string | undefined => {
  const prevMatches = getPreviousMatches(state);
  return detectRoute(prevMatches?.[pervMatchIndex]);
};

const routeToTabsName = (state: RootState, route: string | undefined, queryFeedType: FeedType) => {
  return getFeedOrigin(state, route, queryFeedType) || route;
};

export const getFeedOrigin = (
  state: RootState,
  route: string | undefined,
  queryFeedType: FeedType,
) => {
  const isMainPageCategoriesList = getIsMainPageEnabled(state, state);
  const mainPageFeedType = isMainPageCategoriesList ? FeedType.Categories : FeedType.Posts;

  // This is actually home page, can be posts list as well
  if (route === CATEGORIES_PAGE) {
    return (
      {
        [FeedType.Categories]: FeedType.Categories,
        [FeedType.Posts]: FeedType.Posts,
        [FeedType.MyPosts]: FeedType.MyPosts,
      }[queryFeedType] || mainPageFeedType
    );
  }
};
