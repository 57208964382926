import React, { FC, KeyboardEvent, ChangeEvent, useEffect } from 'react';
import { flowRight } from 'lodash';
import withTranslate from '../../../common/components/with-translate/with-translate';
import classNames from 'classnames';
import { TFunction } from '@wix/yoshi-flow-editor';
import ClearIcon from 'wix-ui-icons-common/on-stage/Clear';
import SearchIcon from 'wix-ui-icons-common/on-stage/Search';
// @ts-expect-error
import { CSSTransitionGroup } from 'react-transition-group';
import { handleEnterKeyUp, handleEscapeKeyUp } from '../../services/accessibility';
import styles from './search-input-suggestions.scss';
import { Popover } from 'wix-ui-tpa';
import { useDebounce } from 'use-hooks';
import { SearchSuggestionsData } from '../../types';
import { ForumapiCategory } from '@wix/ambassador-communities/types';
import {
  loadSuggestionsFromCache,
  clearSuggestions,
  clearValue,
  closeSuggestions,
  loadedSuggestions,
  loadSuggestions,
  openSuggestions,
  reducer,
  setValue,
} from './search-input-suggestions.slice';
import { useReducerRef } from '../../hooks/use-reducer-ref';
import { MAX_RESULTS_COUNT } from './constants/max-results-count';
import { SearchResultsStateSwitch } from './search-results-state-switch/search-results-state-switch.component';
import { EXPERIMENT_SEARCH_SUGGESTIONS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import withExperiment from '../../hoc/with-experiment';
import { decodeQuery } from '../../services/query-encoding';

const CLEAR_ANIMATION_TIMEOUT = 400;
const MIN_QUERY_LENGTH = 2;

interface SearchInputSuggestionsProps {
  t: TFunction;
  onSearch: (query: string) => void;
  onFocus?: () => void;
  onClear?: () => void;
  fetchSearchSuggestions?: (query: string, limit: number) => Promise<SearchSuggestionsData>;
  initialQuery?: string;
  getCategoryById: (categoryId: string) => ForumapiCategory;
  isSearchSuggestionsEnabled: boolean;
}

const SearchInputSuggestions: FC<SearchInputSuggestionsProps> = ({
  t,
  onSearch,
  onFocus,
  onClear,
  initialQuery = '',
  fetchSearchSuggestions = () => Promise.reject(null),
  getCategoryById,
  isSearchSuggestionsEnabled,
}) => {
  const store = useReducerRef(reducer, {
    type: 'CLOSED',
    value: decodeQuery(decodeURIComponent(initialQuery)),
    dropdownState: { type: 'CLOSED' },
    loadedResultsCache: {},
  });
  const [state, dispatch] = store;

  const isInputFocused = state.type === 'OPEN';
  const isPopoverOpened = state.type === 'OPEN' && state.dropdownState.type !== 'CLOSED';
  const debouncedQueryRequestValue = useDebounce(state.value, 400);

  const shouldLoadSuggestions = (value: string) => {
    return isSearchSuggestionsEnabled && value.length >= MIN_QUERY_LENGTH;
  };

  useEffect(() => {
    const [state, dispatch] = store;
    if (isSearchSuggestionsEnabled && shouldLoadSuggestions(debouncedQueryRequestValue)) {
      fetchSearchSuggestions(debouncedQueryRequestValue, MAX_RESULTS_COUNT).then(data => {
        if (state.value !== data.query) {
          return;
        }
        dispatch(loadedSuggestions({ data }));
      });
      return;
    }
  }, [debouncedQueryRequestValue]);

  const closeDropdown = () => dispatch(closeSuggestions());

  const handleEscape = (event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLElement;
    target.blur();
    closeDropdown();
  };

  const handleEnter = () => {
    if (onSearch) {
      onSearch(state.value);
    }
    closeDropdown();
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    handleEnterKeyUp(handleEnter)(event);
    handleEscapeKeyUp(handleEscape)(event);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    dispatch(setValue({ value }));

    if (shouldLoadSuggestions(value)) {
      if (value === debouncedQueryRequestValue) {
        dispatch(loadSuggestionsFromCache({ query: value }));
      } else {
        dispatch(loadSuggestions({ query: value }));
      }
    } else {
      dispatch(clearSuggestions());
    }
  };

  const handleClearButtonClick = () => {
    dispatch(clearValue());
    onClear?.();
  };

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.containerFocused]: isInputFocused,
      })}
    >
      <SearchIcon className={styles.searchIcon} data-hook="search-icon" />
      <form
        className={styles.containerForm}
        onSubmit={ev => ev.preventDefault()}
        autoComplete="off"
      >
        <Popover
          shown={isPopoverOpened}
          showArrow={false}
          placement="bottom"
          contentClassName={styles.popoverContent}
          className={styles.popoverElement}
          onClickOutside={closeDropdown}
        >
          <Popover.Element>
            <>
              <input
                data-hook="search-input"
                className={styles.searchInput}
                onInput={handleOnChange}
                value={state.value}
                type="text"
                autoComplete="off"
                placeholder={t('search-input.placeholder')}
                role="search"
                aria-label={t('search-input.placeholder')}
                onKeyUp={handleKeyUp}
                onFocus={() => {
                  dispatch(openSuggestions());
                  onFocus?.();
                }}
              />
              <CSSTransitionGroup
                component={React.Fragment}
                transitionName="clearButton"
                transitionEnterTimeout={CLEAR_ANIMATION_TIMEOUT}
                transitionLeaveTimeout={CLEAR_ANIMATION_TIMEOUT}
              >
                {!!state.value && (
                  <button
                    className={styles.clearButton}
                    type="button"
                    onClick={handleClearButtonClick}
                    onMouseDown={e => e.preventDefault()}
                    tabIndex={-1}
                    data-hook="search-clear-button"
                  >
                    <ClearIcon size={16} className={styles.clearIcon} />
                  </button>
                )}
              </CSSTransitionGroup>
            </>
          </Popover.Element>
          <Popover.Content>
            <SearchResultsStateSwitch
              state={state}
              t={t}
              getCategoryById={getCategoryById}
              onMoreResultsClick={handleEnter}
              onSearchItemClick={closeDropdown}
            />
          </Popover.Content>
        </Popover>
      </form>
    </div>
  );
};

export default flowRight(
  withTranslate,
  withExperiment({ isSearchSuggestionsEnabled: EXPERIMENT_SEARCH_SUGGESTIONS }),
)(SearchInputSuggestions);
