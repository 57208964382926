import { map } from 'lodash';
import { PLUGINS } from '@wix/communities-forum-client-commons/dist/src/constants/plugins-constants';
import { pluginLink } from 'wix-rich-content-plugin-link/loadable/viewer';
import { pluginHashtag } from 'wix-rich-content-plugin-hashtag/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/loadable/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/loadable/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/loadable/viewer';
import { pluginMentions } from 'wix-rich-content-plugin-mentions/viewer';
import { pluginCodeBlock } from 'wix-rich-content-plugin-code-block/viewer';
import { pluginTextColor, pluginTextHighlight } from 'wix-rich-content-plugin-text-color/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/loadable/viewer';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/loadable/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/loadable/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/viewer';
import { pluginSpoiler } from 'wix-rich-content-plugin-spoiler/viewer';
import { pluginCollapsibleList } from 'wix-rich-content-plugin-collapsible-list/loadable/viewer';
import { pluginPoll } from 'wix-rich-content-plugin-social-polls/loadable/viewer';

export const PLUGIN_MODULES = {
  [PLUGINS.IMAGE]: pluginImage,
  [PLUGINS.VIDEO]: pluginVideo,
  [PLUGINS.LINK]: pluginLink,
  [PLUGINS.HASHTAG]: pluginHashtag,
  [PLUGINS.DIVIDER]: pluginDivider,
  [PLUGINS.HTML]: pluginHtml,
  [PLUGINS.MENTION]: pluginMentions,
  [PLUGINS.CODE_BLOCK]: pluginCodeBlock,
  [PLUGINS.TEXT_COLOR]: pluginTextColor,
  [PLUGINS.TEXT_HIGHLIGHT]: pluginTextHighlight,
  [PLUGINS.GIPHY]: pluginGiphy,
  [PLUGINS.FILE_UPLOAD]: pluginFileUpload,
  [PLUGINS.EMOJI]: pluginEmoji,
  [PLUGINS.LINK_PREVIEW]: pluginLinkPreview,
  [PLUGINS.VERTICAL_EMBED]: pluginVerticalEmbed,
  [PLUGINS.SPOILER]: pluginSpoiler,
  [PLUGINS.COLLAPSIBLE_LIST]: pluginCollapsibleList,
  [PLUGINS.POLLS]: pluginPoll,
};

export const mapConfigToPlugins = config =>
  map(PLUGIN_MODULES, (plugin, key) => {
    const pluginConfig = config[key];
    return pluginConfig ? plugin(pluginConfig) : plugin();
  });
