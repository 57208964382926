import styles from '../search-input-suggestions.scss';
import React from 'react';
import { ForumapiCategory } from '@wix/ambassador-communities/types';
import Renderer from '../../simple-renderer';
import Link from '../../link/internal-link';
import { EXPERIMENT_WIX_COMMENTS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { buildNewDeepCommentUrl } from '../../../services/build-deep-comment-url';
import withExperiment from '../../../hoc/with-experiment';
import { SearchResultComment } from '../../../types';
import { HIGHLIGHT_STYLE } from '../constants/highlight-style';
import { flowRight } from 'lodash';

type SearchResultCommentProps = {
  category: ForumapiCategory;
  isWixCommentsEnabled: boolean;
  comment: SearchResultComment;
  query: string;
  onClick: () => void;
};

const SearchResultCommentItem: React.FC<SearchResultCommentProps> = ({
  category,
  isWixCommentsEnabled,
  comment,
  query,
  onClick,
}) => {
  const commentLink = isWixCommentsEnabled
    ? buildNewDeepCommentUrl(category.slug, comment.postSlug, comment._id)
    : `/main/comment/${comment._id}`;
  return (
    <li data-hook="search-suggestions-dropdown-result-item">
      <Link className={styles.dropdownItem} to={commentLink} onClick={onClick}>
        <h3 className={styles.dropdownItemTitle}>
          <Renderer
            contentState={comment.postTitle!}
            isContentConverted={true}
            query={query}
            highlightStyle={HIGHLIGHT_STYLE}
            clamp={1}
            focusQueryAfterChars={15}
          />
        </h3>
        <p>
          <Renderer
            contentState={comment.content!}
            isContentConverted={false}
            query={query}
            highlightStyle={HIGHLIGHT_STYLE}
            clamp={2}
            focusQueryAfterChars={35}
          />
        </p>
      </Link>
    </li>
  );
};

export default flowRight(withExperiment({ isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS }))(
  SearchResultCommentItem,
);
