import {
  DISCUSSION,
  QUESTION,
} from '@wix/communities-forum-client-commons/dist/src/constants/post-types';

export const mapCommentingToPostType = commentingType => {
  return {
    reaction: [DISCUSSION],
    vote: [QUESTION],
  }[commentingType];
};
