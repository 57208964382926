import { MODAL_TYPE_MOVE_POST } from './move-post-modal/move-post-modal-type';
import { MODAL_TYPE_DELETE_POST } from './delete-post-modal/delete-post-modal-type';
import { MODAL_TYPE_DELETE_COMMENT } from './delete-comment-modal/delete-comment-modal-type';
import { MODAL_TYPE_DISCARD_POST } from './discard-post-modal/discard-post-modal-type';
import { MODAL_TYPE_DISCARD_COMMENT } from './discard-comment-modal/discard-comment-modal-type';
import { MODAL_TYPE_REPORT_POST } from './report-post-modal/report-post-modal-type';
import { MODAL_TYPE_UNFOLLOW_CATEGORY } from './unfollow-category-modal/unfollow-category-modal-type';
import { MODAL_TYPE_REPORT_COMMENT } from './report-comment-modal/report-comment-modal-type';
import { MODAL_TYPE_SHARE_COMMENT } from './share-comment-modal/share-comment-modal-type';
import { MODAL_TYPE_SHARE_POST } from './share-post-modal/share-post-modal-type';
import { MODAL_TYPE_CREATE_POST } from './post-create-modal/post-create-modal-type';
import { MODAL_TYPE_EDIT_POST } from './post-create-modal/post-edit-modal-type';
import { MODAL_TYPE_DELETE_CATEGORY } from './delete-category-modal/delete-category-modal-type';
import { MODAL_TYPE_EDIT_COMMENT } from './comment-edit-modal/comment-edit-modal-type';
import { MODAL_TYPE_DEMO_MODE } from './demo-modal/demo-modal-type';
import {
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES,
  MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS,
} from './manage-category-members-modal/manage-category-members-modal-type';
import { MODAL_TYPE_MOVE_CATEGORY_CONFIRMATION } from './move-category-confirmation-modal/move-category-confirmation-modal-type';
import { MODAL_TYPE_MOVE_POST_CONFIRMATION } from './move-post-confirmation-modal/move-post-confirmation-modal-type';
import { MODAL_TYPE_FILTER_AND_SORT } from './filter-and-sort-modal/filter-and-sort-modal-type';
import { MODAL_TYPE_DISCONNECT_PLAN } from './disconnect-plan-modal/disconnect-plan-modal-type';
import { MODAL_TYPE_WHO_LIKED_LIST } from './who-liked-list-modal/who-liked-list-modal-type';
import {
  MODAL_TYPE_CATEGORY_ACTIONS,
  MODAL_TYPE_CATEGORY_MANAGER_ACTIONS,
  MODAL_TYPE_COMMENT_ACTIONS,
  MODAL_TYPE_CREATE_POST_ACTIONS_MOBILE,
  MODAL_TYPE_FORUM_ACTIONS,
  MODAL_TYPE_POST_ACTIONS,
} from './action-sheet/action-sheet-modal-types';
import { MODAL_TYPE_CREATE_COMMENT } from './comment-create-modal/comment-create-modal-type';
import { MODAL_TYPE_SEARCH_RESULTS_SORT } from './search-results-sort-modal/search-results-sort-modal-type';
import { MODAL_TYPE_UNFOLLOW_ALL_CATEGORIES } from './unfollow-all-categories-modal/unfollow-all-categories-modal-type';
import { loadable } from '@wix/communities-forum-client-commons/dist/src/services/loadable';
import { MODAL_TYPE_POSTING_DISABLED } from './posting-disabled-modal/posting-disabled-modal-type';

const WhoLikedListModalComponent = loadable(() =>
  import(
    /* webpackChunkName: "who-liked-list-modal" */
    './who-liked-list-modal/who-liked-list-modal-component'
  ),
);

const MovePostModal = loadable(() =>
  import(
    /* webpackChunkName: "move-post-modal" */
    './move-post-modal/move-post-modal-component'
  ),
);
const DeletePostModal = loadable(() =>
  import(
    /* webpackChunkName: "delete-post-modal" */
    './delete-post-modal/delete-post-modal-component'
  ),
);
const DeleteCommentModal = loadable(() =>
  import(
    /* webpackChunkName: "delete-comment-modal" */
    './delete-comment-modal/delete-comment-modal-component'
  ),
);
const DeleteCategoryModal = loadable(() =>
  import(
    /* webpackChunkName: "delete-category-modal" */
    './delete-category-modal/delete-category-modal-component'
  ),
);
const DiscardPostModal = loadable(() =>
  import(
    /* webpackChunkName: "discard-post-modal" */
    './discard-post-modal/discard-post-modal-component'
  ),
);
const DiscardCommentModal = loadable(() =>
  import(
    /* webpackChunkName: "discard-comment-modal" */
    './discard-comment-modal/discard-comment-modal-component'
  ),
);
const ReportCommentModal = loadable(() =>
  import(
    /* webpackChunkName: "report-comment-modal" */
    './report-comment-modal/report-comment-modal-component'
  ),
);
const ReportPostModal = loadable(() =>
  import(
    /* webpackChunkName: "report-post-modal" */
    './report-post-modal/report-post-modal-component'
  ),
);
const UnfollowCategoryModal = loadable(() =>
  import(
    /* webpackChunkName: "unfollow-category-modal" */
    './unfollow-category-modal/unfollow-category-modal-component'
  ),
);
const SharePostModal = loadable(() =>
  import(
    /* webpackChunkName: "share-post-modal" */
    './share-post-modal/share-post-modal-component'
  ),
);
const ShareCommentModal = loadable(() =>
  import(
    /* webpackChunkName: "share-comment-modal" */
    './share-comment-modal/share-comment-modal-component'
  ),
);
const PostCreateModal = loadable(() =>
  import(
    /* webpackChunkName: "post-create-modal" */

    './post-create-modal/post-create-modal-component'
  ),
);
const PostEditModal = loadable(() =>
  import(
    /* webpackChunkName: "post-edit-modal" */

    './post-create-modal/post-edit-modal-component'
  ),
);
const CommentEditModal = loadable(() =>
  import(
    /* webpackChunkName: "comment-edit-modal" */

    './comment-edit-modal/comment-edit-modal-component'
  ),
);
const DemoModal = loadable(() =>
  import(
    /* webpackChunkName: "demo-modal" */

    './demo-modal/demo-modal-component'
  ),
);
const ManageCategoryMembersModal = loadable(() =>
  import(
    /* webpackChunkName: "manage-category-members-sm-modal" */

    './manage-category-members-modal/manage-category-members-sm-modal-component'
  ),
);
const ManageCategoryRolesModal = loadable(() =>
  import(
    /* webpackChunkName: "manage-category-members-roles-modal" */

    './manage-category-members-modal/manage-category-members-roles-modal-component'
  ),
);
const ManageCategoryMembersPlansModal = loadable(() =>
  import(
    /* webpackChunkName: "manage-category-members-plans-modal" */
    './manage-category-members-modal/manage-category-members-plans-modal-component'
  ),
);
const MovePostConfirmationModal = loadable(() =>
  import(
    /* webpackChunkName: "move-post-confirmation-modal" */
    './move-post-confirmation-modal/move-post-confirmation-modal-component'
  ),
);
const ManageCategoryMembersBadgesModalComponent = loadable(() =>
  import(
    /* webpackChunkName: "manage-category-members-badges-modal" */
    './manage-category-members-modal/manage-category-members-badges-modal-component'
  ),
);
const MoveCategoryConfirmation = loadable(() =>
  import(
    /* webpackChunkName: "filter-and-sort-plan-modal" */
    './move-category-confirmation-modal/move-category-confirmation-modal-component'
  ),
);
const FilterAndSortModal = loadable(() =>
  import(
    /* webpackChunkName: "filter-and-sort-plan-modal" */
    './filter-and-sort-modal/filter-and-sort-modal-component'
  ),
);
const DisconnectPlanModal = loadable(() =>
  import(
    /* webpackChunkName: "disconnect-plan-modal" */
    './disconnect-plan-modal/disconnect-plan-modal-component'
  ),
);
const ActionSheetModal = loadable(() =>
  import(
    /* webpackChunkName: "action-sheet-modal" */
    './action-sheet/action-sheet-modal-component'
  ),
);

const CommentCreateModal = loadable(() =>
  import(
    /* webpackChunkName: "action-sheet-modal" */
    './comment-create-modal/comment-create-modal-component'
  ),
);

const SearchResultsSortModal = loadable(() =>
  import(
    /* webpackChunkName: "search-results-sort-modal" */
    './search-results-sort-modal/search-results-sort-modal-component'
  ),
);

const UnfollowAllCategoriesModal = loadable(() =>
  import(
    /* webpackChunkName: "unfollow-all-categories-modal" */
    './unfollow-all-categories-modal/unfollow-all-categories-modal-component'
  ),
);

const PostingDisabledModal = loadable(() =>
  import(
    /* webpackChunkName: "unfollow-all-categories-modal" */
    './posting-disabled-modal/posting-disabled-modal-component'
  ),
);

export const modalComponentMapByType = {
  [MODAL_TYPE_CREATE_POST]: PostCreateModal,
  [MODAL_TYPE_DELETE_POST]: DeletePostModal,
  [MODAL_TYPE_DELETE_COMMENT]: DeleteCommentModal,
  [MODAL_TYPE_DELETE_CATEGORY]: DeleteCategoryModal,
  [MODAL_TYPE_DEMO_MODE]: DemoModal,
  [MODAL_TYPE_DISCARD_POST]: DiscardPostModal,
  [MODAL_TYPE_DISCARD_COMMENT]: DiscardCommentModal,
  [MODAL_TYPE_EDIT_POST]: PostEditModal,
  [MODAL_TYPE_EDIT_COMMENT]: CommentEditModal,
  [MODAL_TYPE_FILTER_AND_SORT]: FilterAndSortModal,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_SM]: ManageCategoryMembersModal,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_BADGES]: ManageCategoryMembersBadgesModalComponent,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_ROLES]: ManageCategoryRolesModal,
  [MODAL_TYPE_MANAGE_CATEGORY_MEMBERS_PLANS]: ManageCategoryMembersPlansModal,
  [MODAL_TYPE_MOVE_POST]: MovePostModal,
  [MODAL_TYPE_MOVE_POST_CONFIRMATION]: MovePostConfirmationModal,
  [MODAL_TYPE_MOVE_CATEGORY_CONFIRMATION]: MoveCategoryConfirmation,
  [MODAL_TYPE_REPORT_COMMENT]: ReportCommentModal,
  [MODAL_TYPE_REPORT_POST]: ReportPostModal,
  [MODAL_TYPE_SHARE_POST]: SharePostModal,
  [MODAL_TYPE_SHARE_COMMENT]: ShareCommentModal,
  [MODAL_TYPE_UNFOLLOW_CATEGORY]: UnfollowCategoryModal,
  [MODAL_TYPE_DISCONNECT_PLAN]: DisconnectPlanModal,
  [MODAL_TYPE_WHO_LIKED_LIST]: WhoLikedListModalComponent,
  [MODAL_TYPE_CATEGORY_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_FORUM_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_POST_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_CATEGORY_MANAGER_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_COMMENT_ACTIONS]: ActionSheetModal,
  [MODAL_TYPE_CREATE_POST_ACTIONS_MOBILE]: ActionSheetModal,
  [MODAL_TYPE_CREATE_COMMENT]: CommentCreateModal,
  [MODAL_TYPE_SEARCH_RESULTS_SORT]: SearchResultsSortModal,
  [MODAL_TYPE_UNFOLLOW_ALL_CATEGORIES]: UnfollowAllCategoriesModal,
  [MODAL_TYPE_POSTING_DISABLED]: PostingDisabledModal,
};
