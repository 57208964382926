import { flowRight } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { SORT_BY_NEWEST } from '@wix/communities-forum-client-commons/dist/src/constants/sorting';
import { isCacheableRendering } from '../../../common/store/basic-params/basic-params-selectors';
import withFontClassName from '../../hoc/with-font-class-name';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withAuth from '../../hoc/with-auth';
import withPermissions from '../../hoc/with-permissions';
import { hasCategoryPaidPlans } from '../../selectors/paid-plans-selectors';
import { COVER_TYPE_IMAGE } from '../../constants/cover-types';
import { doesUserHavePermissionsToSeeCategoryActions } from '../../containers/category-actions/category-actions-utils';
import { MODAL_TYPE_CATEGORY_ACTIONS } from '../modals/action-sheet/action-sheet-modal-types';
import Link from '../link/internal-link';
import MoreButtonMobile from '../more-button-mobile';
import NewContentIndicator from '../new-content-indicator';
import ArrowSubcategoriesIcon from '../icons/arrow-subcategories-icon-new';
import PageCover from '../page-cover';
import styles from './category-card-mobile.scss';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import CounterNumber from '../counter-number';

const CategoryCardMobile = ({
  category = {},
  isViewsCountEnabled,
  showViewCount,
  titleFontClassName,
  contentFontClassName,
  hasCategoryPP,
  can,
  isLarge = false,
  isSubcategory = false,
  fetchCategoryPosts,
  resetPostTypeFilter,
  setPostsPageSorting,
  t,
}) => {
  const classes = {
    container: classNames(
      styles.container,
      contentFontClassName,
      'forum-text-color',
      isSubcategory && styles.subcategory,
    ),
    title: classNames(styles.title, titleFontClassName, isSubcategory && styles.subcategory),
    cover: classNames(styles.cover, isLarge && styles.isLarge),
  };

  const hasImageCover = category.coverType === COVER_TYPE_IMAGE;
  const categoryLink = `/${category.slug}`;

  const renderType = () => {
    if (isPrivate(category)) {
      const typeKey = hasCategoryPP
        ? 'category-list-item.subscription'
        : 'category-list-item.private';
      const dataHook = hasCategoryPP ? 'subscribers-type' : 'private-type';
      return (
        <div className={styles.type} data-hook={dataHook}>
          {t(typeKey)}
        </div>
      );
    }
    return null;
  };

  const renderCover = () => {
    return hasImageCover ? (
      <div className={classes.cover}>
        <Link to={categoryLink}>
          <PageCover
            cover={category.cover}
            coverType={category.coverType}
            width={isLarge ? 320 : 48}
            height={isLarge ? 148 : 48}
          />
        </Link>
      </div>
    ) : null;
  };

  const handlePostFetch = sort => () => {
    setPostsPageSorting(sort, { dontReportBI: true });
    resetPostTypeFilter();
    fetchCategoryPosts({ categoryId: category._id, page: 1, sort });
  };

  return (
    <div data-hook={isSubcategory ? 'subcategory-list-item' : 'category-list-item'}>
      {isLarge && !isSubcategory && renderCover()}
      <div className={classes.container}>
        {isSubcategory && (
          <div className={styles.subcategoryIcon}>
            <ArrowSubcategoriesIcon className="forum-icon-fill" />
          </div>
        )}
        {!isLarge && !isSubcategory && renderCover()}
        <div className={styles.infoContainer}>
          <h2
            className={classes.title}
            data-hook={isSubcategory ? 'subcategory-list-item__title' : 'category-list-item__title'}
          >
            <Link className={styles.categoryLink} to={categoryLink}>
              {category.label}
            </Link>
          </h2>
          {renderType()}
          <div className={styles.statistics}>
            <span
              data-hook={
                isSubcategory
                  ? 'subcategory-list-item__total-posts'
                  : 'category-list-item__total-posts'
              }
            >
              <CounterNumber
                tKey="category-list-item.total-posts"
                initialValue={category.totalPosts}
                entityId={category._id}
                totalPosts
              />
            </span>
            {isViewsCountEnabled && showViewCount && (
              <span
                data-hook={
                  isSubcategory
                    ? 'subcategory-list-item__total-views'
                    : 'category-list-item__total-views'
                }
              >
                <CounterNumber
                  tKey="category-list-item.total-views"
                  entityId={category._id}
                  viewCount
                />
              </span>
            )}
          </div>
        </div>
        <Link to={categoryLink} onMouseDown={handlePostFetch(SORT_BY_NEWEST)}>
          <NewContentIndicator
            className={styles.indicator}
            count={category.unseenPostsCount}
            hideWord
            hideDot
          />
        </Link>
        {doesUserHavePermissionsToSeeCategoryActions(can, category) ? (
          <MoreButtonMobile type={MODAL_TYPE_CATEGORY_ACTIONS} category={category} />
        ) : null}
      </div>
    </div>
  );
};

CategoryCardMobile.propTypes = {
  category: PropTypes.object,
  resetPostTypeFilter: PropTypes.func,
  setPostsPageSorting: PropTypes.func,
  can: PropTypes.func,
  showViewCount: PropTypes.bool,
  hasCategoryPP: PropTypes.bool,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isViewsCountEnabled: PropTypes.bool,
  t: PropTypes.func,
  isLarge: PropTypes.bool,
  isSubcategory: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  showViewCount: !isCacheableRendering(state),
  hasCategoryPP: hasCategoryPaidPlans(state, ownProps.category.groups),
  fetchCategoryPosts: actions.fetchCategoryPosts,
  resetPostTypeFilter: actions.resetPostTypeFilter,
  setPostsPageSorting: actions.setPostsPageSorting,
});

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.CATEGORIES, REDUCERS.BASIC_PARAMS]),
  withTranslate,
  withFontClassName,
  withCardBorderWidth,
  withPermissions,
  withAuth,
)(CategoryCardMobile);
