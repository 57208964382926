import { flowRight } from 'lodash';
import React from 'react';
import EmptyStates from '../../components/empty-states';
import LoginButton from '../../components/login-button';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getCategoryFromLocation } from '../../../common/selectors/categories-selectors';
import { connect } from '../../../common/components/runtime-context';
import { isPrivate } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import Page from '../../components/page';
import forDevice from '../../hoc/for-device';
import { FullHeightLayout, SimpleLayout } from '../../components/layout';

const Layout = forDevice(FullHeightLayout, SimpleLayout);

const LoginPage = ({ t, category }) => (
  <Page noSpacing>
    <Layout>
      <EmptyStates
        title={t('login-screen.login-required')}
        content={t('login-screen.login-description')}
        type={isPrivate(category) ? 'specific_members_other' : 'members_only'}
      >
        <LoginButton />
      </EmptyStates>
    </Layout>
  </Page>
);

const mapRuntimeToProps = state => ({
  category: getCategoryFromLocation(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(LoginPage);
